<template>
    <div class="min-h-1100px flex flex-col justify-between pt-2 px-4 " v-if="invoiceItem">
  
      <div class="grid grid-flow-row gap-8">
          <div class="flex justify-between">
              <div>
                  <ul v-if="artistAddress">
                      <li>{{ artistName }}</li>
                      <li v-if="artistAddress.address_line_1">{{ artistAddress.address_line_1 }}</li>
                      <li v-if="artistAddress.address_line_2">{{ artistAddress.address_line_2 }}</li>
                      <li>{{ artistAddress.town }}</li>
                      <li>{{ artistAddress.county }}</li>
                      <li>{{ artistAddress.post_code }}</li>
                      <li v-if="artistEmail">{{ artistEmail }}</li>
                  </ul>
                  <ul v-else>
                      <li>{{ artistName }}</li>
                      <li>Billing address not supplied</li>
                  </ul>
              </div>
              <div class="grid grid-flow-row gap-4"  v-if="invoice">
                  <span class="text-4xl">Invoice</span>
                  <div>
                      <div>Invoice: GP-{{ invoice.invoice_number }}</div>
                      <div>Gig Reference: {{invoiceItem.gig.id}}</div>
                      <div v-if="invoiceItem && invoiceItem.signed_off_at">Date issued: {{signoffFormat(invoiceItem.signed_off_at)}}</div>
                      <div v-if="invoice.utr_number">UTR Number: {{ invoice.utr_number }}</div>
                      <div v-if="invoice.vat_number">VAT Number: {{ invoice.vat_number }}</div>
                  </div>

              </div>
          </div>
          <div class="grid grid-flow-row">
              <div class="font-bold uppercase text-sm">
                  Invoice to
              </div>
                <ul v-if="invoice && invoice.venue_billing_address">
                    <li>{{ invoice.venue_billing_address.company_name }}</li>
                    <li>{{ invoice.venue_billing_address.address_line_1 }}</li>
                    <li v-if="invoice.venue_billing_address.address_line_2">{{ invoice.venue_billing_address.address_line_2 }}</li>
                    <li>{{ invoice.venue_billing_address.town }}</li>
                    <li>{{ invoice.venue_billing_address.county }}</li>
                    <li>{{ invoice.venue_billing_address.post_code }}</li>  
                </ul>
          </div>
          <div class="grid grid-flow-row w-full gap-4" v-if="invoice && invoiceItem">
  
              <div class="uppercase font-bold text-sm">Item description</div>
              <section class="grid grid-flow-row w-full gap-4" v-if="invoice.invoice && !invoice.vat_number">
                  <div class="flex flex-col  justify-between">
                      <div><span v-if="invoiceItem.venue">{{ invoiceItem.venue.name }},</span> {{invoiceItem.gig.name}}, {{format(invoiceItem.gig.start, 'cccc dd/MM/yyyy @ HH:mm')}}</div>
                      <div></div>
                  </div>
                  <div class="flex flex-row justify-between">
                      <div></div>
                    <div>{{invoice.currency.display_name}}{{invoiceItem.fee}}</div>
                  </div>
                  <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Total</div>
                      <div class="font-bold">{{invoice.currency.display_name}}{{invoiceItem.fee}}</div>
                  </div>
              </section>
              <section  class="grid grid-flow-row w-full gap-4" v-else>
                  <div class="flex flex-row  justify-between">
                      <div><span v-if="invoiceItem.venue">{{ invoiceItem.venue.name }},</span> {{invoiceItem.gig.name}}, {{format(invoiceItem.gig.start, 'cccc dd/MM/yyyy @ HH:mm')}}</div>
                      <div>{{invoice.currency.display_name}}{{(invoiceItem.fee)}}</div>
                  </div>
                  <div class="flex flex-row justify-between" v-if="invoice.vat_rate">
                      <div>VAT @ {{invoice.vat_rate}}%</div>
                      <div>{{invoice.currency.display_name}}{{calculateVAT().toFixed(2)}}</div>
                  </div>
                <div class="flex flex-row justify-between" v-if="invoiceItem.card_fee">
                  <div>Card Fee</div>
                  <div>
                    {{invoice.currency.display_name}}{{invoiceItem.card_fee}}
                  </div>
                </div>
                <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
                      <div class="uppercase font-bold text-sm">Total</div>
                      <div class="font-bold">
                        {{invoice.currency.display_name}}{{(cardFee + parseFloat(invoiceItem.fee) + parseFloat(calculateVAT())).toFixed(2)}}
                      </div>
                  </div>      
              </section>
              <div>
                  <h2 class="font-bold">Amounts payable to:</h2>
                  {{invoice.account_name}}
                  <br/>
                  Sort code: {{invoice.sort_code}}
                  <br/>
                  Account: {{invoice.account_number}}
                <br>
                <div v-if="invoice.iban_number">
                  IBAN number: {{invoice.iban_number}}
                </div>
              </div>
          </div>
      </div>
      
      <div v-if="invoice && invoice.payment_terms" class="text-left  flex flex-col justify-between items-start">
          <p><strong>Venue payment terms</strong></p>
          <p v-html="invoice.payment_terms"></p>
      </div>
      <div class="text-center  flex flex-row justify-between items-center">
          
          <a href="https://gigpig.uk">
              <img src="@/assets/images/poweredby_pdf.jpg" alt="Powered by Gig Pig" class="h-12 w-24"/>
          </a>
          <div class="text-right" v-if="invoice">
              Ref {{invoiceItem.id}}/{{invoiceItem.gig.id}}/{{invoice.invoice_number}}
          </div>
      </div>
  </div>
  </template>
  
  <script>
  
  import format from '../../utils/format-date-default' 
  import formatDate from '../../utils/format-date'
  
  const { DateTime } = require("luxon");
  
  export default
  {
      name: "InvoiceContents",
      methods: {
          format,
          formatDate,
          signoffFormat(date){
        
            if (date == null)
            return '';
        
            return DateTime.fromFormat(date, "yyyy-MM-dd HH:mm:ss").toFormat("dd/MM/yyyy")
          },
          calculateVAT(){
              return this.invoiceItem.fee * (this.invoice.vat_rate / 100)
          }
      },
      computed: {
        cardFee() {
          if (!this.invoiceItem.card_fee) {
            return 0.00
          }

          return parseFloat(this.invoiceItem.card_fee)
        },
          invoice() {
                return this.invoiceItem.invoice
          },
          venueBillingAddress() {
                return this.invoiceItem.gig.venue.billing_address
          },
          artistName() {
              if (this.invoice.artist !== null && this.invoice.artist.name)  {
                  return this.invoice.artist.name
              } else if(this.invoice.artist_name) {
                  return this.invoice.artist_name
              }
              return ''
          },
          artistAddress() {
              if (this.invoice.artist !== null && this.invoice.artist.address) {
                  return this.invoice.artist.address
              } else if (this.invoice.artist_billing_address) {
                  return this.invoice.artist_billing_address
              }
              return false
          },
          artistEmail() {
              if (this.invoice.artist !== null && this.invoice.artist.email) {
                  return this.invoice.artist.email
              } else if (this.invoiceItem.artist.email) {
                  return this.invoiceItem.artist.email
              }
              return false
          }
      },    
      props:{
          invoiceItem: Object, 
      }
  }
  </script>