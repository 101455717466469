<template>
  <div class="ml-4">
    <div class="fixed w-full min-h-screen h-full top-0 left-0">
      <div class="w-full min-h-screen h-full flex items-center justify-center">
        <div>
          <div class="text-center">
            <h1 class="text-2xl font-bold gigpig-pink mb-2">Free Trial Reactivation</h1>
            <p v-if="loading" class="text-md text-gray-500 h-[24px]">Please wait...</p>
            <p v-else class="text-md text-gray-500 h-[24px]">{{ message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {apiMethods} from "@/state/helpers";

export default
{
  name: "ReactivateFreeTrial",
  methods: {
    ...apiMethods
  },
  data() {
    return {
      loading: true,
      message: '',
    }
  },
  mounted(){
    this.reactivateFreeTrial().then((response) => {
      if (response.status === 204) {
        this.message = 'Your free trial has been successfully reactivated.'
      } else {
        this.message = 'Expired.'
      }
    }).finally(() => {
      this.loading = false;
    });

  }

}

</script>

<style>
.gigpig-pink {
  color: #EC55D8;
}

.reskin #mobile-sidebar, .reskin .venuenav {
  z-index: 10000;
}
</style>