
<template>
  <div :class="classes">
    <div>
      <p class="text-sm text-gray-400 font-bold">{{ title }}</p>
      <h3 class="text-gray-900 text-2xl">{{ stat }}</h3>
    </div>
    <img
        class="w-12 absolute right-6 rounded-full"
        :src=icon
    />
  </div>
</template>

<script type="text/javascript">

export default {
  name: 'AnalyticsTile',
  props: { 
    title: {
      type: String,
      default: ''
    },
    stat: {
      type: [String, Number],
      default: '0',
    },
    icon: {
      type: String,
      default: '/img/icons/mstile-150x150.png',
      description: "A URL or relative path for image to be shown as icon"
    },
    useFixedClasses: {
      type: Boolean,
      default: true
    },
    customClasses: {
      type: [String, Object],
      default: null
    }
  },
  data: () => {
    return {
      fixedClasses: 'flex items-center m-2 relative shadow rounded-2xl p-8',
    };
  },
  computed: {
    classes: function(){
      return this.useFixedClasses ? this.fixedClasses : this.customClasses;
    }
  }
};

</script>