<template>

    <div class="ml-4 max-w-5xl">
        
        <InlineNotification Heading="Favourite artists" DismissId="VenueFaveList">
            <template slot="body">
                <p>
                    You can remove artists and delete your list from the options below. 
                </p>
            </template>
        </InlineNotification>

			<template v-if="favelist">
				<form class="form mb-4 gap-2">
						<InputText class="col-span-2" v-if="favelist" v-model="favelist.name" name="name" label="List name" required />
						<a @click="saveList()" class="gp_cta self-end justify-center ">Rename list</a>
				</form>


				<Accordian  v-if="showSharedToggle" heading="Settings"  :toggle="settingsOpen">
					<template #contents>
							<div class="">
								<div class="form-group sm:col-span-1 grid grid-flow-col items-center">
									<label for="enabled">
									Enable Brand Visibility
								</label>
								<t-toggle @change="saveList()" name="allow_brand_visibility" id="allow_brand_visibility" v-model="favelist.allow_brand_visibility" class="justify-self-end" />
								</div>
							</div>    
					</template> 
                </Accordian>


		
				<div class="flex flex-col ">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div
							class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
						>
							<div v-if="favelist && favelist.artists.length > 0"
								class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
							>
								<table class="min-w-full divide-y divide-gray-200 bg-white">
									<thead class="bg-gray-50">
										<tr>
											<th
												scope="col"
												class="px-6 py-3 w-40 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												
											</th>
											<th
												scope="col"
												class="px-6 py-3 w-40 marker: text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												
											</th>
											<th
												scope="col"
												class="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Artist
											</th>
											<th
												scope="col"
												class="px-6 py-3 text-left  text-xs font-medium text-gray-500 uppercase tracking-wider"
											>
												Type
											</th>
											<th
												scope="col"
												class="px-6 py-3  text-xs font-medium text-gray-500 uppercase tracking-wider"
											></th>
										</tr>
									</thead>
									<tbody class="bg-white divide-y divide-gray-200"> 
										<tr v-for="(artist) in favelist.artists" :key="artist.id">
												<td class="p-6 ">
														<div class="flex space-x-8 justify-center items-center text-center">
															<a title="Remove from list" class="text-gp_pink-default-500" @click="removeArtist(artist.id)">
																	<TrashIcon/>
															</a>
															<router-link title="View artist" :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">
																	<EyeIcon />
															</router-link>
														</div>
												</td>
												<td class="text-left mx-2 p-6 min-w-180px relative">
													
														<img :src="artist.image ? artist.image.url : require('@/assets/images/placeholder.svg')" :alt="artist.name" class="w-28 h-28 object-cover object-center" />
															<router-link :to="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="absolute top-0 left-0 w-full h-full"></router-link>
												</td> 
												<td class="text-left mx-2 p-6 relative">
														<strong>{{artist.name}}</strong><br/>
														<!-- <StarRating class="mt-2" :rating="artist.rating"/> -->
														<!-- {{artist.artist_tags.map(tag => tag.name).join(', ')}} -->
											
											</td> 
												<td class="p-6">
														{{artist.tier.name}}
												</td>
										</tr>
	
									</tbody>
								</table>
							</div>
							<div v-else>
								<p class="mt-4">
										No artists have been added to this list.  <router-link to="/artist-directory">Use the artist directory to add your artists</router-link>.                   
								</p>
							</div>
						</div>
					</div>
				</div>

        <div class="action-bar justify-between">                
            <router-link to="/favourites"  class="cta icon bg-indigo-500 self-start"><RewindIcon class="mr-4" />Back to lists</router-link>
            <button class="cta icon bg-indigo-500 " @click="deleteList()"><TrashIcon class="mr-4" /> Delete list</button>                
        </div>



			</template>
    </div>


    

</template>

<script>

import { RewindIcon, TrashIcon, EyeIcon } from '@vue-hero-icons/outline'
import { apiComputed, apiMethods } from '@/state/helpers';  
import InlineNotification from '../../components/notifications/inline.vue';
import InputText from '../../components/form/InputText.vue';
import slugify from 'slugify';
import NProgress from 'nprogress';
import client from "../../utils/client"
import Accordian from '../../components/accordian/Accordian.vue';
import { TToggle } from 'vue-tailwind/dist/components';
// import StarRating from '../../components/rating/StarRating.vue';

export default
    {
        name: "FavouritesDetails",
        components:{
        InlineNotification,
        InputText,
        TrashIcon,
        RewindIcon,EyeIcon,
		Accordian,
		TToggle,
		// StarRating,
		
    },
		props:['favouriteListId'],
    data(){
        return {
			favelist: null,
			isLoading:false,
			settingsOpen: true,
			user: null,
			showSharedToggle: false
        }
    },
	created() {
		this.user = this.$store.getters['user/userData'];
	},
    mounted() {

			this.isLoading = true;

			client.get(`/favourites-lists/` + this.favouriteListId).then((response) => {
				this.favelist = response.data.data;
				this.favelist.allow_brand_visibility = Boolean(this.favelist.allow_brand_visibility);
			}).finally(() => {
				this.isLoading = false;
			});

    },
	watch: {
		user() {
			if (this.user.brand) {
				this.showSharedToggle = (this.user.brand.id === 8 || this.user.brand.id === 31)
			}
		}
	},
    computed:{
        ...apiComputed
    },
    methods:{
        ...apiMethods,
        slugify,
        saveList(){    
          let self = this;      
            this.updateFavesList(this.favelist).then(function() {
              self.$notify("List Saved");
            });
        },
        removeArtist($favelistartist){
			
			NProgress.start();
			let self = this;  

			this.deleteFavesListArtist({
				"favelist": self.favelist.id,
				"favelistartist": $favelistartist
			})
			.then(() => {
				this.favelist.artists.splice(this.favelist.artists.findIndex((f) => f.id === $favelistartist), 1);
				self.$notify("Artist removed");
			})
			.finally(() => {
				NProgress.done();
			})
	
        },
        deleteList(){
          let self = this;      
          console.log("deleting", self.favelist);
              this.deleteFavesList(self.favelist).then(function(){
                self.$notify("List deleted");
                self.$router.push('/favourites');
              });
        },
    },
}

</script>

