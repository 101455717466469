
<template>
  <div class="m-2 relative shadow rounded-2xl p-8">
    <div class="grid grid-cols-7 gap-4 w-full">
      <div class="lg:col-span-2 md:col-span-4 col-span-7">
        <ul class="description-lines text-sm">
          <li>Actual spend of booked gigs</li>
          <li>Potential spend of pending gigs</li>
          <li>Combined Actual and Potential spend</li>
        </ul>
      </div>
      <div class="lg:col-span-2 md:col-span-3 col-span-7">
        <TSelect name="analyticsVenues"
                 ref="analyticsVenues"
                 class="w-full block"
                 v-model="venues"
                 placeholder="All"
                 value-attribute="id" 
                 text-attribute="name"
                 :options="getUserVenues"
        />
      </div>
      <div class="lg:col-span-3 md:col-span-7 col-span-7">
        <div class="flex flex-row items-center">
          <div class="w-full block">
            <TDatePicker
                userFormat="D d/m/Y"
                v-model="startDate"
                @change="fetchAnalytics"
            />
          </div>
          <div class="mx-2">
            <span>to</span>
          </div>
          <div class="w-full block">
          <TDatePicker
              userFormat="D d/m/Y"
              v-model="endDate"
              @change="fetchAnalytics"
          />
        </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-4 w-full mt-4">
      <AnalyticsLineChart v-if="chartData" :lineData="chartData"/>
    </div>
  </div>
</template>

<script type="text/javascript">
import TDatePicker from "vue-tailwind/dist/t-datepicker";
import {TSelect} from "vue-tailwind/dist/components";
import AnalyticsLineChart from "./analytics-line-chart.vue";
import {debounce} from "lodash";



export default {
  name: 'BasicAnalyticChart',
  components: {AnalyticsLineChart, TDatePicker, TSelect},
  data: () => {
    return {
      startDate: '',
      endDate: '',
      venues: [],
    }
  },
  props:{
    userVenues: {
      type: Array,
    },
    spending: {
      type: [Object, Array]
    }
  },
  watch:{
    venues: function(){
      this.fetchAnalytics();
    }
  },
  computed: {
    getUserVenues: function(){
      return this.userVenues;
    },
    chartData: function(){
      return this.spending;
    }
  },
  mounted() {
    this.startDate = this.initialDate();
    this.endDate = this.initialDate(false);
    this.fetchAnalytics();
  },
  methods: {
    fetchAnalytics: debounce(function(){
      let filter_date = {
        start_date: this.startDate,
        end_date: this.endDate,
        venues: this.venues
      };
      this.setDates(this.startDate, this.endDate);
      this.$emit("fetchAnalytics", filter_date);
    }, 500),
    initialDate(start = true){
      let dates = localStorage.getItem('analyticDates');
      if(dates){
        dates = JSON.parse(dates);
        return start ? dates.start : dates.end;
      }
      else {
        this.setDates();
      }
      return this.initialDate(start);
    },
    setDates(start, end){
      const now = new Date(); // Get the current date
      localStorage.setItem('analyticDates',  JSON.stringify({
        start: start ? new Date(start) : new Date(now.getFullYear(), 0, 1),
        end: end ? new Date(end) : new Date(now.getFullYear(), 11, 31)
      }));
    }
  }
};
</script>

<style>
.description-lines{
  list-style: inside;
}
.description-lines li::marker {
  font-size: 20px;
}
.description-lines li:nth-child(1){ color: #C9A859; }
.description-lines li:nth-child(2){ color: #081331; }
.description-lines li:nth-child(3){ color: #4A82D7; }
</style>