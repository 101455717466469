<template>
	<div class="flex items-center justify-between border-b border-gray-200 ">

		<div class="sm:hidden">
			<label for="tabs" class="sr-only">Select a tab</label>
      <select @change="onSelectItemClicked" id="tabs" name="tabs" class="block w-full pl-3 pr-10 py-1 my-3 text-gray-500 hover:text-gray-700 font-medium text-sm border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
        <option 
					v-for="tab in tabs" 
					:key="tab.route" 
					:selected="tab.current"
					:value="tab.route"
					class="cursor-pointer"
				>
					{{ tab.title }}
				</option>
      </select>
		</div>

		<div class="hidden sm:block">
			<nav class="-mb-px flex items-center justify-between overflow-hidden" aria-label="Tabs">
				<div class="grow flex items-start lg:space-x-8 space-x-4">
					<!-- <slot default></slot> -->
					<router-link 
						v-for="tab in tabs" 
						:to="tab.route"
						:key="tab.route" 
						@click.native.prevent.capture="allowedToRoute(tab)">
						<span v-show="!gig" class="cursor-pointer">{{ tab.title }}</span>

						<span v-show="gig" :class="gig && !gig.payment_terms && (tab.title === 'Choose artists' || 
															tab.title === 'Repeat gig' ||
															tab.title === 'Notes' ||
															tab.title === 'Offers') ? 'cursor-not-allowed' : 'cursor-pointer'">{{ tab.title }}</span>


						<div v-show="tab.title === 'Artist instructions' && !gig.payment_terms" class="inline-block w-1 h-1 right-0 top-0 z-10 rotate-0 skew-x-0 skew-y-0 scale-x-50 scale-y-50 rounded-full bg-red-400 p-1.5 text-xs font-bold text-white"></div>
						<!-- and if gig payment details are null -->
						<template v-if="tab.notifications >= 0">
							<span class="bg-indigo-100 text-indigo-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
								{{ tab.notifications }}
							</span>     
						</template>      
					</router-link>
				</div>
			</nav>
		</div>
		
		<div class="flex items-center justify-end">
			<slot name="right-cta"></slot>
		</div>
		
	</div>
</template>

<script>

export default {
  props: {
		"tabs":Array,
		"gig": Object
	},
	computed: {
		hasPaymentTerms() {
			return this.gig !== undefined && this.gig.payment_terms
		}
	},
	methods:{
		onSelectItemClicked(event){
			console.log(event.target.value)
			this.$router.push(event.target.value)
		},
		allowedToRoute($tabData){
	
			if (this.gig && !this.gig.payment_terms && ($tabData.title === 'Choose artists' || $tabData.title === 'Repeat gig' || $tabData.title === 'Notes' || $tabData.title === 'Manage Venue Subscription' || $tabData.title === 'Offers')){
				return;
			}
			
			this.$router.push($tabData.route.toString());
		}
	}
};
</script>

<style lang="postcss" scoped>
	a,
	a.router-link-active{
    @apply selection:border-transparent text-gray-500  hover:text-gray-700  hover:border-gray-200 whitespace-nowrap flex py-4 px-1 font-medium text-sm;
	}
	.router-link-active{
    @apply bg-transparent;
	}
	a.router-link-exact-active{
		@apply border-indigo-500 text-indigo-600 hover:border-indigo-500 hover:text-indigo-600 border-b-2 bg-transparent;
	}
	
</style>