import client from "../../utils/client"
import { getInstance } from "../../auth";

export const state = {
  accInfo: null,
  venueInfo: null,
  loadingAccInfo: false
}

export const mutations = {
  SET_ACC_INFO(state, accInfo) { 
    state.accInfo = accInfo;
  },
  SET_VENUE_INFO(state, venueInfo) {
    state.venueInfo = venueInfo;
  },
  SET_LOADING_ACC_INFO(state, loading) {
    state.loadingAccInfo = loading;
  }
}

export const actions = {
  updateAccInfo({ commit }, accInfo) {
    console.log("updating acc info", accInfo);
    commit('SET_ACC_INFO', accInfo);
  },
  updateVenueInfo({ commit }, venueInfo) {
    console.log("updating venue info", venueInfo);
    commit('SET_VENUE_INFO', venueInfo);
  },
  async getUserInfo({ commit, state }) {
    console.log("Getting user info");

    if (state.accInfo !== null) {
      console.log("Using cached user info");
      return Promise.resolve(state.accInfo);
    }

    commit('SET_LOADING_ACC_INFO', true);

    try {
      const authService = await getInstance();

      if (!authService) {
        console.error("Auth service is not available");
        throw new Error("Auth service is not available");
      }

      const token = await authService.getTokenSilently();

      if (!token) {
        console.error("Unable to get token");
        throw new Error("Unable to get token");
      }

      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      const response = await client.get(`/user/sub-info`, config);
      commit('SET_ACC_INFO', response.data);
      return response.data;
    } 
    catch (error) {

      console.error("Error in getUserInfo:", error);
      return null;

    } finally {

      commit('SET_LOADING_ACC_INFO', false);

    }
  }
}

export const getters = {
  getAccInfo: state => state.accInfo,
  getVenueInfo: state => state.venueInfo,
  isLoadingAccInfo: state => state.loadingAccInfo
}