<template>
  <PaymentModalTemplate :show="showModal" @closeModal="close" @modalRendered="modalRendered">
    <template #header><span class="text-md text-gp_pink-default-500 font-bold">Enter Card Details</span></template>
    <template #default>

        <form id="ryft-pay-form" class="h-[78px] mt-3">
          <!-- form will be embedded here -->
          <div id="ryft-pay-error"></div>
          <!-- <p>I would like to submit my card details</p> -->
        </form>
    </template>

    <template #buttons>
      <div class="flex justify-end gap-4 mt-3">
        <!-- <button
            id="mitSubmitFormButton"
            class="inline-flex items-center border text-base font-medium rounded-md shadow-sm justify-center text-center transform-all ease-in duration-100 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 px-4 py-2 bg-gp_pink-default-500 hover:bg-pink-200 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 pink border-transparent hover:border-transparent h-auto"
            :class="{'bg-indigo-700': submitButtonDisabled, 'bg-pink-200': submitButtonDisabled, 'border-transparent': submitButtonDisabled, 'text-white': submitButtonDisabled, 'cursor-default': submitButtonDisabled}"
            :disabled="submitButtonDisabled"
        >Submit</button> -->

        <!-- <div class="w-full pr-10 mt-10"> -->
                                <GPButton id="mitSubmitFormButton"
                                                :disabled="submitButtonDisabled"
                                                :loading="isProcessing"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Submit</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            <!-- </div> -->
      </div>
    </template>
  </PaymentModalTemplate>

</template>

<script>
import PaymentModalTemplate from "@/components/PaymentModalTemplate.vue";
import {apiMethods} from "@/state/helpers";
import { ArrowNarrowRightIcon } from "@vue-hero-icons/outline"
import GPButton from '../../components/ui/buttons/Button.vue';

export default {
  name: "SubmitMITPayment",
  components: {
    PaymentModalTemplate,
    ArrowNarrowRightIcon,
    GPButton
  },
  data() {
    return {
      submitButtonDisabled: true,
      isLoading: true,
      isProcessing: false,
    }
  },
  methods: {
    ...apiMethods,
    close() {
      this.$emit('showMITPaymentModal', false)
      this.submitButtonDisabled = false
    },
    modalRendered() {
      const script = document.createElement('script');
      script.src = 'https://embedded.ryftpay.com/v1/ryft.min.js?v=' + new Date().getTime();
      document.body.appendChild(script);
      script.onload = () => {
        this.isLoading = false;
      };
      script.onerror = () => {
        console.error('Failed to load the script.');
        this.isLoading = false; // Optionally, handle error state differently
      };
    }
  },
  props: {
    showModal: {type: Boolean, default: false},
    MITPublicKey: {type: String, default: ''},
    MITClientSecret: {type: String, default: ''},
    customerEmail: {type: String, default: ''}
  },
  watch: {
    isLoading: function (val) {
      if (!val) {
        /* eslint-disable */
        Ryft.init({
          publicKey: this.MITPublicKey,
          clientSecret: this.MITClientSecret,
          paymentType: 'Unscheduled',
          usage: 'setupCard',
          style: {
                borderRadius: 6,
                backgroundColor: "#FFF",
                borderColor: "#000",
                padding: 10,
                color: "#000",
                focusColor: "#FFF",
                bodyColor: "#000000",
          }
        });

        Ryft.addEventHandler("cardValidationChanged", e => {

          this.submitButtonDisabled = !e.isValid;

          this.$emit('cardNumberIsValid', e.isValid)
        });

      }

      const formSubmitButton = document.getElementById("mitSubmitFormButton");

      formSubmitButton.addEventListener("click", async (e) => {

        this.submitButtonDisabled = true;
        this.isProcessing = true;
        this.$emit('formSubmitted')

        const attemptPaymentRequest = {
          customerEmail: this.customerEmail
        };

        Ryft.attemptPayment(attemptPaymentRequest).then((paymentSession) => {
          if (
              paymentSession.status === 'Approved' ||
              paymentSession.status === 'Captured'
          ) {
            // Payment successful - show the customer your order success page
            this.$emit('cardSubmissionComplete');
            return;
          }
          if (paymentSession.lastError) {
            const userFacingError = Ryft.getUserFacingErrorMessage(paymentSession.lastError);
            this.$emit('formFailed');
            this.submitButtonDisabled = false;
            this.isProcessing = false;
            console.log('userFacingError in Ryfy SDK response', userFacingError);
            this.$notify(userFacingError);
            // Show userFacingError to customer
          }
        })
            .catch((error) => {
              this.$notify(`Error. Please contact support`);
              this.submitButtonDisabled = false;
              this.isProcessing = false;
              console.log('Ryft SDK error', error)
            });
      });
    },
  },
  emits: ['showMITPaymentModal', 'cardSubmissionComplete', 'formSubmitted', 'cardNumberIsValid']
}
</script>

<style>
#ryft-pay-iframe {
  height: 55px;
}
</style>