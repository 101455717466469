<template>
  <div class="grid grid-flow-row w-full gap-2 mb-4">

      <section class="grid grid-flow-row w-full gap-4" v-if="!invoiceItem.vat_number">
        <div class="flex flex-row  justify-between"> 
          <div>
            
            {{ invoiceItem.gig.venue.name }}
            <br/>
            {{ invoiceItem.gig.name }}
            <br/>
            {{ format(invoiceItem.gig.start, 'dd/MM/yyyy @ HH:mm') }}
          </div>
          <div>{{ invoiceCurrencyDisplayName }}{{ invoiceItem.fee }}</div>
        </div>
 
      </section>
      <section class="grid grid-flow-row w-full gap-4" v-else>
        <div class="flex flex-row  justify-between">
          <div>{{ invoiceItem.gig.name }}, {{ format(invoiceItem.gig.start, 'dd/MM/yyyy @ HH:mm') }}</div>
          <div class="row-span-2">{{ invoiceCurrencyDisplayName }}{{ (invoiceItem.fee) }}</div>
        </div>
        <div class="flex flex-row justify-between" v-if="invoiceItem.vat_number">
          <div>VAT @ {{ invoiceItem.vat_rate }}%</div>
          <div>{{ invoiceCurrencyDisplayName }}{{ calculateVAT().toFixed(2) }}</div>
        </div>
        <div class="flex flex-row justify-between" v-if="invoiceItem.card_fee">
          <div>Card Fee</div>
          <div>{{ invoiceCurrencyDisplayName }}{{ cardFee }}</div>
        </div>
        <div class="flex flex-row justify-between  border-solid border-t-2 border-gray-500">
          <div class="uppercase font-bold text-sm">{{ feeOrTotal }}</div>
          <div class="font-bold">{{ invoiceCurrencyDisplayName }}{{ (parseFloat(invoiceItem.fee) + parseFloat(calculateVAT())).toFixed(2) }}</div>
        </div>
      </section> 
      <div class="html2pdf__page-break" v-if="index % 5 == 0 && index != 0">
      </div>

  </div>
</template>

<script>
import format from '../../utils/format-date-default'

export default {
  props: {
    index: {
      type: Number
    },
    invoiceItem: {
      type: Object
    },
    feeOrTotal: {
      type: String,
      default: 'Total'
    },
    invoiceCurrencyDisplayName: {
      type: String,
      default: '£',
      required: false
    }
  },
  methods: {
    format,
    calculateVAT() {
      return this.invoiceItem.fee * (this.invoiceItem.vat_rate / 100)
    }
  },
  computed: {
    cardFee() {
      if (!this.invoiceItem.card_fee) {
        return 0.00
      }

      return parseFloat(this.invoiceItem.card_fee)
    },
  }
}
</script>